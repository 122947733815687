import { Component, OnDestroy, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { FantasyOwner, FinancialSummary, ModelFilter, Player, PropertyFilter } from "../model";
import { FantasyOwnerService, StateService } from "../service";
import { FinancialSummaryService } from "../service/financial-summary.service";
import { PlayerService } from "../service/player.service";
import { Client, StompSubscription } from '@stomp/stompjs';
import { HeadshotService } from "../service/headshot.service";

@Component({
    selector: 'draft-component',
    templateUrl: 'draft.component.html'
})
export class DraftComponent implements OnInit, OnDestroy {
    numberOfLoads: number = 0;
    owners: FantasyOwner[];
    players: Player[];
    pageStart: number;
    financialSummaries: FinancialSummary[];
    
    client: Client;
    subscription: StompSubscription;

    constructor(
        private playerService: PlayerService,
        private ownerService: FantasyOwnerService,
        private financialSummaryService: FinancialSummaryService,
        private headshotService: HeadshotService,
        public stateService: StateService

    ) {

    }
    
    ngOnInit(): void {
        this.loadOwners();
        this.loadFinancialSummaries();
        this.loadPlayers();

        this.initializeWebSocketConnection();
        
    }

    initializeWebSocketConnection() {
        if (environment.WS_ENABLED) {
            this.client = new Client({
                brokerURL: environment.WS_ENDPOINT,
                debug: function (str) {
                //console.log(str);
                },
                reconnectDelay: 5000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000,
            });
            
            let that = this;
            this.client.onConnect = function (frame) {
                // Do something, all subscribes must be done is this callback
                // This is needed because this will be executed after a (re)connect
                    that.subscription = that.client.subscribe('/topic/players', function (message) {
                        // called when the client receives a STOMP message from the server
                        if (message.body) {
                            //console.log('got message with body ' + message.body);
                        } else {
                            //console.log('got empty message');
                        }
                        that.loadPlayers();
                        that.loadFinancialSummaries();
                    }
                );

            };
            
            this.client.onStompError = function (frame) {
                // Will be invoked in case of error encountered at Broker
                // Bad login/passcode typically will cause an error
                // Complaint brokers will set `message` header with a brief message. Body may contain details.
                // Compliant brokers will terminate the connection after any error
                console.log('Broker reported error: ' + frame.headers['message']);
                console.log('Additional details: ' + frame.body);
            };
            
            this.client.activate();
        }

      }

    loadFinancialSummaries() {
        this.financialSummaryService.searchAll().subscribe(
            financialSummaries => {
                this.financialSummaries = financialSummaries;
            }
        )
    }

    loadOwners() {
        const filter = new ModelFilter();
        filter.addFilter(PropertyFilter.stringEquals('leagueId', this.stateService.league ? this.stateService.league.id : null));

        this.ownerService.searchAll(undefined, filter).subscribe(
            owners => {
                this.owners = owners;
            }
        )
    }

    loadPlayers() {
        const filter = new ModelFilter();
        filter.addFilter(PropertyFilter.stringEquals('leagueId', this.stateService.league ? this.stateService.league.id : null));
        filter.addFilter(PropertyFilter.booleanEquals('takenOnly', true));
        this.numberOfLoads++;
        this.playerService.search(0, 9, undefined, filter, 'draftTime', 'desc').subscribe(
            page => {
                this.players = page.content;
                //console.log("players", this.players);
                this.pageStart = page.totalElements - page.numberOfElements;

                for (let player of this.players) {
                    let mlbPlayerId = this.headshotService.getPlayerId(player);
                    if (mlbPlayerId) {
                        let imageUrl = "https://img.mlbstatic.com/mlb-photos/image/upload/w_426,q_100/v1/people/"+mlbPlayerId+"/headshot/67/current";
                        player.imageUrl = imageUrl;
                    }
                }
                this.numberOfLoads--;
            }
        );
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.client) {
            this.client.deactivate();
        }
    }


    getOwner(ownerId: string): FantasyOwner {
        let result: FantasyOwner = null;
        for (const o of this.owners) {
            if (o.id === ownerId) {
                result = o;
                break;
            }
        }
        return result;
    }

}