import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from "@angular/core";
import { FantasyOwner, Hitter, ModelFilter, Pitcher, Player, PropertyFilter } from "../model";
import { combineLatest, Subject, Subscription } from "rxjs";
import { PlayerService } from "../service/player.service";
import { FantasyOwnerService, StateService } from "../service";
import { DataTableDirective } from 'angular-datatables';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UberdraftUtil } from "../util/uberdraft.util";

@Component({
    selector: 'list-players-component',
    templateUrl: 'list-players.component.html'
})
export class ListPlayersComponent implements OnInit, OnDestroy {
    players: Player[];
    filteredPlayers: Player[];

    owners: FantasyOwner[];
    dtOptions: DataTables.Settings;
    dtTrigger: Subject<any> = new Subject<any>();

    positionFilter: string;
    sport: string;
    
    @ViewChild(DataTableDirective, {static: false})
    datatableElement: DataTableDirective;
    
    @ViewChild('globalSearchElement') 
    globalSearchElement: ElementRef;

    globalSearch: string;

    showAvailableOnly=false;
    hideLowRankedPlayers = true;
    showKeepersOnly=false;
    
    @ViewChild('editPlayerModal')
    editPlayerModalTemplate: TemplateRef<any>;
    
    modalRef: BsModalRef;
    
    player: Player;
    keeperPriceRange: number[];
    ownerPriceRange: number[];
    //map of owner to players they own of a certain position
    ownedPlayers: Map<string, Player[]>;
    undraftedPlayers: Player[];
    undraftedPlayers2: Player[];
    tierLowPrice: number;
    tierHighPrice: number;
    
    keeperPhase: boolean;
    keeperSetup: boolean;
    noteType:string='';

    category: string;

    subscriptions: Subscription[] = [];

    positionRanks: Map<string, number>;

    sortProperty: string;
    sortOrder: string;
    
    constructor(private ownerService: FantasyOwnerService, 
        private playerService: PlayerService,
        private modalService: BsModalService, 
        private renderer: Renderer2,
        private changeDetection: ChangeDetectorRef,
        private stateService: StateService
        ) {}
    
    ngOnInit(): void {
        this.positionFilter = 'Players';
        this.globalSearch = '';
        this.showAvailableOnly = false;
        this.hideLowRankedPlayers = true;
        this.keeperPhase = false;
        this.keeperSetup = false;
        this.showKeepersOnly = false;
        this.sport = this.stateService.league ? this.stateService.league.sport : null;
        this.owners = this.stateService.league.owners;
        //sort owners by name
        this.owners = this.owners.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        //this.loadOwners();
        this.loadPlayers();
    }

    loadOwners() {
        const filter = new ModelFilter();
        filter.addFilter(PropertyFilter.stringEquals('leagueId', this.stateService.league ? this.stateService.league.id : null));
        this.ownerService.searchAll(undefined, filter).subscribe(
            owners => {
                this.owners = owners;
                this.loadPlayers();
            }
        )
    }

    loadPlayers() {
        const filter = new ModelFilter();
        filter.addFilter(PropertyFilter.stringEquals('leagueId', this.stateService.league ? this.stateService.league.id : null));
        this.playerService.searchAll(undefined, filter, 'overallRank', 'asc').subscribe(
            players => {
                this.players = players;
                this.filteredPlayers = [];
                this.filterPlayers();
                //this.calculatePositionRanks();

                let that = this;
                let previousTier = -1;
                this.dtOptions = {
                    data: this.filteredPlayers,
                    pagingType: 'simple_numbers',
                    pageLength: 25,
                    lengthChange: false,
                    order: [[1, 'asc']],
                    rowId: 'id',
                    dom: 'itp',
                    // initComplete: function(settings, json) {
                    //     if (that.keeperPhase && !that.keeperSetup && !that.showKeepersOnly) {
                    //         that.onKeepersButtonClick();
                    //     }
                    // },
                    preDrawCallback: function(settings) {
                        previousTier = -1;
                        
                        var api = new $.fn.dataTable.Api( settings );
                        let order = api.order();
                        let sortColIndex = order[0][0]; //index of sorted column
                        that.sortOrder = order[0][1] as string;
                        let column = api.column(sortColIndex);
                        that.sortProperty = column.dataSrc() as string;
                    },
                    rowCallback: function(row, data, index) {
                        $(row).attr("data-player-id",(data as any).id);
                        if (that.sortProperty === 'overallRank' || that.sortProperty === 'tier') {
                            let name = data['name'];
                            let tier = data['tier'];
                            if (previousTier != -1 && tier != previousTier) {
                                //console.log("tiers = "+previousTier+", "+tier+" for "+name);
                                $(row).addClass("heavyTopBorder");
                            } else {
                                $(row).removeClass("heavyTopBorder");
                            }
                            previousTier = tier;
                        } else {
                            $(row).removeClass("heavyTopBorder");
                        }
                    },
                    columns: [
                        { 
                            name: "overallRank",
                            title: "#", 
                            type: "num",
                            defaultContent: "999",
                            data: "overallRank",
                            className:"ud-num-col"
                        },
                        { 
                            name: "positionTier",
                            title: "Tier", 
                            type: "num",
                            defaultContent: "",
                            data: "tier",
                            visible: this.sport === 'FOOTBALL',
                            className:"ud-num-col"
                        },                           
                        { 
                            name: "projectedDollar",
                            title: "Proj $", 
                            type: "num",
                            defaultContent: "",
                            data: "projectedDollar",
                            orderSequence: ['desc', 'asc'],
                            className:"ud-num-col"
                        },
                        { 
                            name: "name",
                            title: "Name", 
                            type: "html",
                            defaultContent: "",
                            data: "name",
                            render: function(data, type, row, meta) {
                                if (type === 'display') {
                                    let noteTypeClass = '';
                                    if (row.ownerId) {
                                        noteTypeClass = "taken";
                                    } else if (row.noteType === 'target') {
                                        noteTypeClass = 'text-success';
                                    } else if (row.noteType === 'avoid') {
                                        noteTypeClass = 'text-danger';
                                    } else if (row.noteType === 'nominate') {
                                        noteTypeClass = 'text-warning';
                                    }
                                    let result = "<a href='javascript:void(0)' data-player-id='"+row.id+"' class='"+noteTypeClass+"'>";
                                    result += data;
                                    if (row.team) {
                                        result += " ("+row.team+")";
                                    }
                                    result += "</a>";
                                    return result;
                                }
                                return data;
                            }
                        },     
                        { 
                            name: "pos",
                            title: "Pos", 
                            type: "string",
                            defaultContent: "",
                            data: "position"
                        },
                        { 
                            name: "bye",
                            title: "Bye", 
                            type: "num",
                            defaultContent: "",
                            data: "bye",
                            visible: this.sport === 'FOOTBALL',
                        },                  
                        // { 
                        //     name: "adp",
                        //     title: "ADP", 
                        //     type: "num",
                        //     defaultContent: "",
                        //     data: "overallADP",
                        //     className:"ud-num-col"
                        // },                        
                        // { 
                        //     name: "adpDelta",
                        //     title: "ADP &Delta;", 
                        //     type: "num",
                        //     defaultContent: "",
                        //     data: function( row, type, val, meta) {
                        //         let result = null;
                        //         if (row.overallADP && row.overallRank) {
                        //             result = UberdraftUtil.roundTo2Digits(row.overallADP - row.overallRank);
                        //         }
                        //         return result;
                        //     },
                        //     render: function(data, type, row, meta) {
                        //         if (type === "display") {
                        //             if (data) {
                        //                 let result =  data;
                        //                 let spanClass="";
                        //                 if (result <= -12) {
                        //                     spanClass = "font-weight-bold text-danger";
                        //                 } else if (result <= -8) {
                        //                     spanClass = "text-danger";
                        //                 } else if (result <= -3) {
                        //                     spanClass = "text-warning";
                        //                 } else if (result >= 12) {
                        //                     spanClass = "font-weight-bold text-success";
                        //                 } else if (result >= 8) {
                        //                     spanClass = "text-success";
                        //                 } else if (result >= 3) {
                        //                     spanClass = "text-info";
                        //                 }
                        //                 return "<span class='"+spanClass+"'>"+result+"</span>";
                        //             }
                        //         }
                        //         return data;
                        //     },
                        //     className:"ud-num-col"
                        // },          
                        // { 
                        //     name: "positionRank",
                        //     title: "Pos<br>Rank", 
                        //     type: "num",
                        //     defaultContent: "",
                        //     data: "positionRank",
                        //     visible: true,
                        //     className:"ud-num-col"
                        // },       
                        // { 
                        //     name: "calculatedPositionRank",
                        //     title: "Pos<br>Rank", 
                        //     type: "num",
                        //     defaultContent: "",
                        //     visible: false,
                        //     data( row, type, set, meta ) {
                        //         let result = null;
                        //         if (that.positionRanks) {
                        //             result = that.positionRanks.get(row.id);
                        //         }
                        //         return result;
                        //     },
                        //     className:"ud-num-col"
                        // },       
                        { 
                            name: "keeper",
                            title: "Keeper", 
                            type: "string",
                            defaultContent: "",
                            data: "keeperOwner",
                            visible: this.keeperPhase,
                            render: function(data, type, row, meta) {
                                if (type === 'display' || type === 'sort') {
                                    let result = '';
                                    if (data) {
                                        result = data.name;
                                    }
                                    return result;
                                }
                                return data;
                            }
                        },
                        { 
                            name: "keeperPrice",
                            title: "Keep $", 
                            type: "num",
                            defaultContent: "",
                            data: "keeperPrice",
                            visible: this.keeperPhase,
                            className:"ud-num-col"
                        },
                        { 
                            name: "keeperDelta",
                            title: "Keep &Delta;", 
                            type: "num",
                            defaultContent: "",
                            data: function( row, type, val, meta) {
                                let result = null;
                                if (row.projectedDollar && row.keeperPrice) {
                                    result = row.projectedDollar - row.keeperPrice;
                                    //round to 2 digits
                                    result = UberdraftUtil.roundTo2Digits(result);
                                }
                                return result;
                            },
                            visible: this.keeperPhase,
                            render: function(data, type, row, meta) {
                                if (type === "display") {
                                    if (data) {
                                        let result =  data;
                                        let spanClass="";
                                        if (result <= -12) {
                                            spanClass = "font-weight-bold text-danger";
                                        } else if (result <= -8) {
                                            spanClass = "text-danger";
                                        } else if (result <= -3) {
                                            spanClass = "text-warning";
                                        } else if (result >= 12) {
                                            spanClass = "font-weight-bold text-success";
                                        } else if (result >= 8) {
                                            spanClass = "text-success";
                                        } else if (result >= 3) {
                                            spanClass = "text-info";
                                        }
                                        return "<span class='"+spanClass+"'>"+result+"</span>";
                                    }
                                }
                                return data;
                            },
                            className:"ud-num-col"
                        },                           
                        { 
                            name: "owner",
                            title: "Owner", 
                            type: "string",
                            defaultContent: "",
                            data: "owner",
                            render: function(data, type, row, meta) {
                                if (type === 'display' || type === 'sort') {
                                    let result = '';
                                    if (data) {
                                        result = data.name;
                                    }
                                    return result;
                                }
                                return data;
                            }                            
                        },                        

                        { 
                            name: "ownerPrice",
                            title: "Own $", 
                            type: "num",
                            defaultContent: "",
                            data: "ownerPrice",
                            className:"ud-num-col"
                        },
                        { 
                            name: "ownerPriceDelta",
                            title: "Own $ &Delta;", 
                            type: "num",
                            defaultContent: "",
                            data: function( row, type, val, meta) {
                                let result = null;
                                if (row.projectedDollar && row.ownerPrice) {
                                    result = row.projectedDollar - row.ownerPrice;
                                    //round to 2 digits
                                    result = UberdraftUtil.roundTo2Digits(result);
                                }
                                return result;
                            },
                            render: function(data, type, row, meta) {
                                if (type === "display") {
                                    if (data) {
                                        let result =  data;
                                        let spanClass="";
                                        if (result <= -12) {
                                            spanClass = "font-weight-bold text-danger";
                                        } else if (result <= -8) {
                                            spanClass = "text-danger";
                                        } else if (result <= -3) {
                                            spanClass = "text-warning";
                                        } else if (result >= 12) {
                                            spanClass = "font-weight-bold text-success";
                                        } else if (result >= 8) {
                                            spanClass = "text-success";
                                        } else if (result >= 3) {
                                            spanClass = "text-info";
                                        }
                                        return "<span class='"+spanClass+"'>"+result+"</span>";
                                    }
                                }
                                return data;
                            },
                            className:"ud-num-col"
                        },  
                        { 
                            name: "positionAdp",
                            title: "Pos ADP", 
                            type: "num",
                            defaultContent: "",
                            data: "positionADP",
                            render: function(data, type, row, meta) {
                                if (type === "display") {
                                    if (data) {
                                        let result = data;
                                        let diff = 0;
                                        if (row.positionRank) {
                                            diff = data - row.positionRank;
                                            result += " ("+UberdraftUtil.roundTo2Digits(diff)+")";
                                        }
                                        let spanClass="";
                                        if (diff <= -12) {
                                            spanClass = "font-weight-bold text-danger";
                                        } else if (diff <= -8) {
                                            spanClass = "text-danger";
                                        } else if (diff <= -3) {
                                            spanClass = "text-warning";
                                        } else if (diff >= 12) {
                                            spanClass = "font-weight-bold text-success";
                                        } else if (diff >= 8) {
                                            spanClass = "text-success";
                                        } else if (diff >= 3) {
                                            spanClass = "text-info";
                                        }
                                        return "<span class='"+spanClass+"'>"+result+"</span>";
                                    }
                                }
                                return data;
                            },
                            visible: false,
                            className:"ud-num-col"
                        },                        
                        { 
                            name: "ab_ip",
                            title: "AB/IP", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: this.sport === 'BASEBALL',
                            data: function(row, type, val, meta) {
                                
                                if (row.type === 'hitter') {
                                    return row.atBats;
                                } else if (row.type === 'pitcher') {
                                    return row.inningsPitched;
                                }
                                return null;
                            },
                            className:"ud-num-col"
                        },
                        { 
                            name: "r_k",
                            title: "R/K", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: this.sport === 'BASEBALL',
                            data: function(row, type, val, meta) {
                                
                                if (row.type === 'hitter') {
                                    return row.runs;
                                } else if (row.type === 'pitcher') {
                                    return row.strikeouts;
                                }
                                return null;
                            },
                            className:"ud-num-col"
                        },  
                        { 
                            name:"hr_era",
                            title: "HR/ERA", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: this.sport === 'BASEBALL',
                            data: function(row, type, val, meta) {
                                
                                if (row.type === 'hitter') {
                                    return row.homeRuns;
                                } else if (row.type === 'pitcher') {
                                    return row.era;
                                }
                                return null;
                            },
                            className:"ud-num-col"
                        },  
                        { 
                            name: "rbi_whip",
                            title: "RBI/WHIP", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: this.sport === 'BASEBALL',
                            data: function(row, type, val, meta) {
                                
                                if (row.type === 'hitter') {
                                    return row.runsBattedIn;
                                } else if (row.type === 'pitcher') {
                                    return row.whip;
                                }
                                return null;
                            },
                            className:"ud-num-col"
                        },  
                        { 
                            name: "sb_qs",
                            title: "SB/QS", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: this.sport === 'BASEBALL',
                            data: function(row, type, val, meta) {
                                
                                if (row.type === 'hitter') {
                                    return row.stolenBases;
                                } else if (row.type === 'pitcher') {
                                    return row.qualityStarts;
                                }
                                return null;
                            },
                            className:"ud-num-col"
                        }, 
                        { 
                            name: "obp_sv+h",
                            title: "OBP/SV+H", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: this.sport === 'BASEBALL',
                            data: function(row, type, val, meta) {
                                
                                if (row.type === 'hitter') {
                                    return row.onBasePercentage;
                                } else if (row.type === 'pitcher') {
                                    return row.savesPlusHolds;
                                }
                                return null;
                            },
                            className:"ud-num-col"
                        },
                        {
                            name: "passAttempts",
                            title: "Pass<br>Att", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.passAttempts;
                            },
                            className:"ud-num-col"
                        },

                        {
                            name: "passCompletions",
                            title: "Pass<br>Cmp", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.passCompletions;
                            },
                            className:"ud-num-col"
                        },
                        {
                            name: "passYards",
                            title: "Pass<br>Yds", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.passYards;
                            },
                            className:"ud-num-col"
                        },
                        {
                            name: "passTouchdowns",
                            title: "Pass<br>TDs", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.passTouchdowns;
                            },
                            className:"ud-num-col"
                        },  
                        {
                            name: "interceptions",
                            title: "Pass<br>Ints", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.interceptions;
                            },
                            className:"ud-num-col"
                        },                                             
                        {
                            name: "rushAttempts",
                            title: "Rush<br>Att", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.rushAttempts;
                            },
                            className:"ud-num-col"
                        },
                        {
                            name: "rushYards",
                            title: "Rush<br>Yds", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.rushYards;
                            },
                            className:"ud-num-col"
                        },
                        {
                            name: "rushTouchdowns",
                            title: "Rush<br>TDs", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.rushTouchdowns;
                            },
                            className:"ud-num-col"
                        },
                        {
                            name: "receptions",
                            title: "Rec", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.receptions;
                            },
                            className:"ud-num-col"
                        },
                        {
                            name: "receiveYards",
                            title: "Rec<br>Yds", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.receiveYards;
                            },
                            className:"ud-num-col"
                        },
                        {
                            name: "receiveTouchdowns",
                            title: "Rec<br>TDs", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.receiveTouchdowns;
                            },
                            className:"ud-num-col"
                        },
                        {
                            name: "fumbles",
                            title: "Fumbles", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.fumbles;
                            },
                            className:"ud-num-col"
                        }, 
                        {
                            name: "fieldGoals",
                            title: "FG", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.fieldGoals;
                            },
                            className:"ud-num-col"
                        },   
                        {
                            name: "fieldGoalAttempts",
                            title: "FG<br>ATT", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.fieldGoalAttempts;
                            },
                            className:"ud-num-col"
                        },   
                        {
                            name: "extraPoints",
                            title: "XP", 
                            type: "num",
                            defaultContent: "",
                            orderSequence: ['desc', 'asc'],
                            visible: false,
                            data: function(row, type, val, meta) {
                                return row.extraPoints;
                            },
                            className:"ud-num-col"
                        },                     
                        { 
                            name: "fantasyPoints",
                            title: "Points", 
                            type: "num",
                            defaultContent: "",
                            data: function(row, type, val, meta) {
                                return row.fantasyPoints;
                            },
                            visible: this.sport === 'FOOTBALL',
                            className:"ud-num-col"
                        },             
                        {
                            name:"noteType",
                            title:"Note Type",
                            type:"string",
                            visible: false,
                            defaultContent: "",
                            data: "noteType"
                        },
                    ]
                };
            }
        );
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }


    resetFocus() {
        this.globalSearchElement.nativeElement.focus();
        this.globalSearchElement.nativeElement.select();
    }

    onClear() {
        this.globalSearch = '';
        this.globalSearchChange(this.globalSearch);
        this.resetFocus();
    }

    onAvailableButtonClick() {
        this.showAvailableOnly = !this.showAvailableOnly;
        this.setVisibleColumns();

        this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
                if (this.showAvailableOnly) {
                    dtInstance.column("owner:name").search("^$", true, false).draw();
                } else {
                    dtInstance.column("owner:name").search("", false, true).draw();
                }
            }
        );

        this.resetFocus();
    }

    onHideLowRankedPlayersButtonClick() {
        this.hideLowRankedPlayers = !this.hideLowRankedPlayers;
        this.filterPlayers();
        
        if (this.datatableElement?.dtInstance) {
            this.datatableElement.dtInstance.then(
                (dtInstance: DataTables.Api) => {
                    dtInstance.clear();
                    dtInstance.rows.add(this.filteredPlayers).draw();
                }
            );
        }  
        this.resetFocus();
    }


    onNoteTypeButtonClick(noteType: string) {
        if (this.noteType === noteType) {
            this.noteType = '';
        } else {
            this.noteType = noteType;
        }
        this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
                dtInstance.column("noteType:name").search(this.noteType, false, true).draw();
            }
        );

        this.resetFocus();
    }
    onKeeperPhaseButtonClick() {
        this.keeperPhase = !this.keeperPhase;
        if (!this.keeperPhase && this.showKeepersOnly) {
            this.onKeepersButtonClick();
        // } else if (this.keeperPhase && !this.showKeepersOnly) {
        //     this.onKeepersButtonClick();
        }
        this.setVisibleColumns();

        this.resetFocus();
    }

    setVisibleColumns() {
        let visibleColumns = [];
        let hiddenColumns = [];
        if (this.keeperPhase) {
            visibleColumns.push("keeper", "keeperPrice", "keeperDelta");
        } else {
            hiddenColumns.push("keeper", "keeperPrice", "keeperDelta");
        }
        // if (this.positionFilter === 'Players') {
        //     visibleColumns.push("positionRank");
        // } else {
        //     hiddenColumns.push("positionRank");
        // }

        if (this.showAvailableOnly) {
            hiddenColumns.push("owner", "ownerPrice", "ownerPriceDelta");
        } else {
            visibleColumns.push("owner", "ownerPrice", "ownerPriceDelta");
        }

        if (this.positionFilter === 'QB') {
            visibleColumns.push("passAttempts", "interceptions", "passCompletions", "passTouchdowns", "passYards");
        } else {
            hiddenColumns.push("passAttempts", "interceptions", "passCompletions", "passTouchdowns", "passYards");
        }

        if (this.positionFilter === 'QB' || this.positionFilter === 'RB' || this.positionFilter === 'WR' || this.positionFilter === 'FLEX') {
            visibleColumns.push("rushAttempts", "rushTouchdowns", "rushYards");
        } else {
            hiddenColumns.push("rushAttempts", "rushTouchdowns", "rushYards");
        }

        if (this.positionFilter === 'QB' || this.positionFilter === 'RB' || this.positionFilter === 'WR' || this.positionFilter === 'FLEX' || this.positionFilter === 'TE') {
            visibleColumns.push("fumbles");
        } else {
            hiddenColumns.push("fumbles");
        }

        if (this.positionFilter === 'RB' || this.positionFilter === 'WR' || this.positionFilter === 'FLEX' || this.positionFilter === 'TE') {
            visibleColumns.push("receptions", "receiveYards", "receiveTouchdowns");
        } else {
            hiddenColumns.push("receptions", "receiveYards", "receiveTouchdowns");
        }

        if (this.positionFilter === 'K') {
            visibleColumns.push("fieldGoals", "fieldGoalAttempts", "extraPoints");
        } else {
            hiddenColumns.push("fieldGoals", "fieldGoalAttempts", "extraPoints");
        }

        if (this.positionFilter === 'DEF') {
            visibleColumns.push("sacks", "defenseInterceptions", "fumblesRecovered", "fumblesForced", "defenseTouchdowns", "safeties", "pointsAgainst", "yardsAgainst");
        } else {
            hiddenColumns.push("sacks", "defenseInterceptions", "fumblesRecovered", "fumblesForced", "defenseTouchdowns", "safeties", "pointsAgainst", "yardsAgainst");
        }
        this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
                for (let c of visibleColumns) {
                    let col = dtInstance.column(c+":name");
                    if (col) {
                        col.visible(true, true);
                    }
                }
                for (let c of hiddenColumns) {
                    let col = dtInstance.column(c+":name");
                    if (col) {
                        col.visible(false, true);
                    }
                }
            }
        );
    }
    
    onKeeperSetupButtonClick() {
        this.keeperSetup = !this.keeperSetup;
        this.resetFocus();
    }

    onKeepersButtonClick() {
        this.showKeepersOnly = !this.showKeepersOnly;
        this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
                if (this.showKeepersOnly) {
                    dtInstance.column("keeper:name").search(".+", true, false).draw();
                } else {
                    dtInstance.column("keeper:name").search("", false, true).draw();
                }
            }
        );

        this.resetFocus();
    }

    globalSearchChange(value: string) {
        this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
                dtInstance.column("name:name").search(value, false, true).draw();
            }
        );
    }

    onGlobalSearchEnterKeyUp(event: any) {
        this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
                let selectedIds = dtInstance.rows({
                   search: 'applied' 
                }).ids();
                if (selectedIds && selectedIds.length == 1) {
                    let id = selectedIds[0];
                    if (id) {
                        this.selectPlayer(id);
                    }
                }
            }
        );
    }

    setPositionFilter(newFilter: string) {
        this.positionFilter = newFilter;
        this.setVisibleColumns();
        this.calculatePositionRanks();

        this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
                let search = this.getCurrentSearchFilter();
                let regex = true;
                let smart = false;
                let reorder = null;

                if (this.positionFilter === 'Players') {
                    this.category = null;
                } else if (this.sport === 'BASEBALL' && this.positionFilter === 'Pitchers') {
                    this.category = 'pitchers';
                } else if (this.sport === 'BASEBALL' && this.positionFilter === 'SP') {
                    this.category = 'pitchers';
                } else if (this.sport === 'BASEBALL' && this.positionFilter === 'RP') {
                    this.category = 'pitchers';
                } else if (this.sport === 'BASEBALL') {
                    this.category = 'hitters';
                } else if (this.sport === 'FOOTBALL' && this.positionFilter === 'FLEX') {
                    this.category = null;
                } else if (this.sport === 'FOOTBALL') {
                    this.category = null;
                }
                if (reorder) {
                    this.datatableElement.dtInstance.then(
                        (dtInstance: DataTables.Api) => {
                            dtInstance.order(reorder);
                            let col = dtInstance.column("pos:name");
                            col.search(search, regex, smart).draw();
                            this.adjustColumnHeaders(dtInstance);
                        }
                    );
                } else {
                    let col = dtInstance.column("pos:name");
                    col.search(search, regex, smart).draw();
                    this.adjustColumnHeaders(dtInstance);
                }
            }
        )
    }


    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: 'modal-xl',
            animated: false
        });

        const _combine = combineLatest(
            this.modalRef.onHide,
            this.modalRef.onHidden
          ).subscribe(() => this.changeDetection.markForCheck());
       
          this.subscriptions.push(
            this.modalRef.onHide.subscribe((reason: string | any) => {
            //   if (typeof reason !== 'string') {
            //     reason = `onHide(), modalId is : ${reason.id}`;
            //   }
            //   const _reason = reason ? `, dismissed by ${reason}` : '';
            //   console.log(`onHide event has been fired${_reason}`);
            this.resetFocus();
            })
          );
          this.subscriptions.push(
            this.modalRef.onHidden.subscribe((reason: string | any) => {
            //   if (typeof reason !== 'string') {
            //     reason = `onHide(), modalId is : ${reason.id}`;
            //   }
            //   const _reason = reason ? `, dismissed by ${reason}` : '';
            //   console.log(`onHidden event has been fired${_reason}`);
              this.unsubscribe();
            })
          );
       
          this.subscriptions.push(_combine);
    }

    unsubscribe() {
        this.subscriptions.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
        });
        this.subscriptions = [];
      }

    ngAfterViewInit(): void {
        this.renderer.listen('document', 'click', (event) => {
            let playerId = null;

            let target = event.target;
            if (target.hasAttribute("data-player-id")) {
                playerId = target.getAttribute("data-player-id");
            } else {
                let playerRow = $(target).closest("tr:has(*[data-player-id])");
                //console.log("playerRow", playerRow);
                    
                if (playerRow) {
                    playerId = $(playerRow).attr("data-player-id");
                }
            }

            if (playerId) {
                this.selectPlayer(playerId);
            }
        });
    }

    selectPlayer(playerId: string) {
        this.player = this.getPlayer(playerId);
                
        if (this.player) {
            this.undraftedPlayers = [];
            this.ownedPlayers = new Map<string, Player[]>();
            this.tierLowPrice = null;
            this.tierHighPrice = null;

            for (const p of this.players) {
                if (p.position === this.player.position) {
                    if (p.tier === this.player.tier) {
                        if (!this.tierLowPrice || p.projectedDollar < this.tierLowPrice) {
                            this.tierLowPrice = p.projectedDollar;
                        }
                        if (!this.tierHighPrice || p.projectedDollar > this.tierHighPrice) {
                            this.tierHighPrice = p.projectedDollar;
                        }
                    }
                    if (p.owner) {
                        let players = this.ownedPlayers.get(p.owner.id);
                        if (!players) {
                            players = [];
                            this.ownedPlayers.set(p.owner.id, players);
                        }
                        players.push(p);
                    } else if (p.keeperOwner) {
                        let players = this.ownedPlayers.get(p.keeperOwner.id);
                        if (!players) {
                            players = [];
                            this.ownedPlayers.set(p.keeperOwner.id, players);
                        }
                        players.push(p);
                    } else if (p.id !== this.player.id) {
                        this.undraftedPlayers.push(p);
                    }
                }
            }
            for (let o of this.owners) {
                let players = this.ownedPlayers.get(o.id);
                if (players) {
                    //sort the players by position rank
                    players = players.sort((a, b) => {
                        if (a.positionRank < b.positionRank) {
                            return -1;
                        } else if (a.positionRank > b.positionRank) {
                            return 1;
                        }
                        return 0;
                    });
                    this.ownedPlayers.set(o.id, players);
                }
                //console.log("players", players);
            }
            this.undraftedPlayers = this.undraftedPlayers.sort((a, b) => {
                if (a.positionRank < b.positionRank) {
                    return -1;
                } else if (a.positionRank > b.positionRank) {
                    return 1;
                }
                return 0;
            });
            if (this.undraftedPlayers.length > 10) {
                this.undraftedPlayers2 = this.undraftedPlayers.slice(6, 11);
            }
            if (this.undraftedPlayers.length > 5) {
                this.undraftedPlayers = this.undraftedPlayers.slice(0, 5);
            }

            if (this.keeperPhase) {
                this.player.kept = this.player.keeperOwner === this.player.owner;
            }
            if (this.keeperPhase && this.keeperSetup) {
                this.keeperPriceRange = this.createKeeperPriceRange(this.player);
            }
            if (!this.keeperSetup) {
                this.ownerPriceRange = this.createOwnerPriceRange(this.player);
            }

            this.openModal(this.editPlayerModalTemplate);
        }
    }

    getPlayer(playerId: string) : Player {
        let result: Player = null;
        for (const p of this.players) {
            if (p.id === playerId) {
                result = p;
                break;
            }
        }
        return result;
    }

    getOwner(ownerId: string): FantasyOwner {
        let result: FantasyOwner = null;
        for (const o of this.owners) {
            if (o.id === ownerId) {
                result = o;
                break;
            }
        }
        return result;
    }

    createKeeperPriceRange(player: Player): number[] {
        let min = 5;
        let max = 52;
        return this.createRange(min, max);
    }

    createOwnerPriceRange(player: Player): number[] {
        let min:number = 1;
        if (player.ownerPrice) {
            min = +player.ownerPrice;
        } 
        if (player.keeperPrice > min) {
            min = +player.keeperPrice;
        }
        let max : number = min;
        if (this.keeperPhase && this.player.keeperPrice) {
            max = min+15;
        } else {
            max = min + 47;
        }
        return this.createRange(min, max);
    }


    createRange(min: number, max: number): number[]{
        let result:number[] = [];
        for (let i = min; i <= max; i++) {
            result.push(i);
        }
        return result;
    }

    getDiscountedPrice(keeperPrice: number, price: number) : number {
        return price - Math.ceil((price - keeperPrice)*.33);
    }

    updatePlayer(player: Player) {
        this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
                let row = dtInstance.row('#'+player.id);
                if (row) {
                    row.invalidate('auto');
                    dtInstance.draw(false);
                }
            }
        );      

        this.playerService.update(player).subscribe(
            result => {
                //do nothing
            },
            error => {
                console.log(error);
            }
        )
    }

    setKeeperOwner(player: Player, owner: FantasyOwner) {
        player.keeperOwner= owner;
        this.updatePlayer(player);

    }

    setKeeperPrice(player: Player, price: number) {
        player.keeperPrice = +price;
        this.updatePlayer(player);
    }


    setOwner(player: Player, owner: FantasyOwner) {
        player.owner = owner;
        player.draftTime = new Date();
        this.updatePlayer(player);
    }

    setOwnerPrice(player: Player, price: number) {
        if (player.keeperPrice && price < player.keeperPrice) {
            price = player.keeperPrice;
        }
        player.ownerPrice = +price;
        player.draftTime = new Date();
        this.updatePlayer(player);
        if (player === this.player) {
            if (price < this.ownerPriceRange[0] || price > this.ownerPriceRange[this.ownerPriceRange.length-3]) {
                this.ownerPriceRange = this.createOwnerPriceRange(player);
            }
        }
    }

    onKeepButtonClick(player: Player) {
        if (!player.kept) {
            player.kept = true;
            player.owner = player.keeperOwner;
            player.ownerPrice = this.getDiscountedPrice(player.keeperPrice, player.ownerPrice);
            this.updatePlayer(player);
        } else {
            player.kept = false;
            player.owner= null;
            player.ownerPrice = null;
            this.updatePlayer(player);
        }
        this.modalRef.hide()
    }

    onClearDraftButtonClick(player: Player) {
        player.owner = null;
        player.ownerPrice = null;
        player.kept = false;
        player.draftTime = null;
        this.updatePlayer(player);
        this.ownerPriceRange = this.createOwnerPriceRange(player);
    }

    onClearKeeperButtonClick(player: Player) {
        player.keeperOwner = null;
        player.keeperPrice = null;
        player.kept = false;
        this.updatePlayer(player);
        this.keeperPriceRange = this.createKeeperPriceRange(player);
    }

    asHitter(player: Player) : Hitter{
        return player as Hitter;
    }

    asPitcher(player: Player): Pitcher {
        return player as Pitcher;
    }

    adjustColumnHeaders(dtInstance: DataTables.Api) {
        if (this.category === 'hitters') {
            $(dtInstance.columns('ab_ip:name').header()).text('AB');
            $(dtInstance.columns('r_k:name').header()).text('R');
            $(dtInstance.columns('hr_era:name').header()).text('HR');
            $(dtInstance.columns('rbi_whip:name').header()).text('RBI');
            $(dtInstance.columns('sb_qs:name').header()).text('SB');
            $(dtInstance.columns('obp_sv+h:name').header()).text('OBP');
        } else if (this.category === 'pitchers') {
            $(dtInstance.columns('ab_ip:name').header()).text('IP');
            $(dtInstance.columns('r_k:name').header()).text('K');
            $(dtInstance.columns('hr_era:name').header()).text('ERA');
            $(dtInstance.columns('rbi_whip:name').header()).text('WHIP');
            $(dtInstance.columns('sb_qs:name').header()).text('QS');
            $(dtInstance.columns('obp_sv+h:name').header()).text('SV+H');
        } else {
            $(dtInstance.columns('ab_ip:name').header()).text('AB/IP');
            $(dtInstance.columns('r_k:name').header()).text('R/K');
            $(dtInstance.columns('hr_era:name').header()).text('HR/ERA');
            $(dtInstance.columns('rbi_whip:name').header()).text('RBI/WHIP');
            $(dtInstance.columns('sb_qs:name').header()).text('SB/QS');
            $(dtInstance.columns('obp_sv+h:name').header()).text('OBP/SV+H');
        }
    }

    getAdditionalPriceButtonClass(chosenPrice: number, projectedDollar: number, price: number) {
        let result = '';
        const diff:number = projectedDollar - price;
        if (chosenPrice && chosenPrice == price) {
            result = 'btn-primary';
        } else if (diff >= 5) {
            result = 'btn-success';
        } else if (diff >= 2) {
            result = 'btn-outline-success';
        } else if (diff == 0) {
            result = 'btn-info';
        } else if (diff <= -5) {
            result = 'btn-danger';
        } else if (diff <= -2) {
            result = 'btn-outline-danger';
        } else {
            result = 'btn-outline-secondary';
        }
        return result;
    }

    setNoteType(player: Player, noteType: string) {
        if (player.noteType === noteType) {
            player.noteType = null;
        } else {
            player.noteType = noteType;
        }
        this.updatePlayer(player);
    }

    filterPlayers() {
        while(this.filteredPlayers.length > 0) {
            this.filteredPlayers.pop();
        }

        if (this.players) {
            for (let p of this.players) {
                if (this.hideLowRankedPlayers) {
                    if (p.overallRank <= 500) {
                        this.filteredPlayers.push(p);
                    }
                } else {
                    this.filteredPlayers.push(p);
                }
            }
        }
        //console.log("this.filteredPlayers", this.filteredPlayers);
    }
    calculatePositionRanks() {
        this.positionRanks = new Map<string, number>();
        let i = 1;
        let regex = new RegExp(this.getCurrentSearchFilter());
        for (let p of this.filteredPlayers) {
            if (regex.test(p.position)) {
                this.positionRanks.set(p.id, i++);
            }
        }
        
        //console.log("positionRanks", this.positionRanks);
        if (this.datatableElement?.dtInstance) {
            this.datatableElement.dtInstance.then(
                (dtInstance: DataTables.Api) => {
                    dtInstance.rows().invalidate();
                }
            );
        }      
    }


    getCurrentSearchFilter() : string {
        let search : string = '';
        if (this.positionFilter === 'Players') {
            search = '';
        } else if (this.sport === 'BASEBALL' && this.positionFilter === 'Hitters') {
            search = 'C|1B|2B|3B|SS|OF|Util|DH|CF|RF|LF';
        } else if (this.sport === 'BASEBALL' && this.positionFilter === 'Pitchers') {
            search = 'SP|RP|P'; 
        } else if (this.sport === 'BASEBALL' && this.positionFilter === 'IF') {
            search = '1B|2B|3B|SS'; 
        } else if (this.sport === 'BASEBALL' && this.positionFilter === 'OF') {
            search = 'OF|RF|CF|LF'; 
        } else if (this.sport === 'BASEBALL' && this.positionFilter === 'CI') {
            search = '1B|3B'; 
        } else if (this.sport === 'BASEBALL' && this.positionFilter === 'MI') {
            search = '2B|SS'; 
        } else if (this.sport === 'BASEBALL' && this.positionFilter === 'C') {
            search = 'C,|C$'; 
        } else if (this.sport === 'BASEBALL' && this.positionFilter === 'SP') {
            search = 'SP'; 
        } else if (this.sport === 'BASEBALL' && this.positionFilter === 'RP') {
            search = 'RP'; 
        } else if (this.sport === 'FOOTBALL' && this.positionFilter === 'FLEX') {
            search = 'RB|WR';
        } else {
            search = this.positionFilter; 
        }
        return search;
    }

}


