<div class="container-fluid" *ngIf="owners && financialSummaries && players; else loading">
    <div class="row mt-2">
        <div class="col-12">
            <a href="javascript:void(0)" [routerLink]="['/']" class="float-end">Home</a>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-lg-6 col-sm-12">
            <table class="table table-striped table-sm table-bordered">
                <thead>
                    <tr class="table-primary">
                        <th class="h4 p-2">Owner</th>
                        <th class="h4 p-2 text-center">Max&nbsp;Bid</th>
                        <th class="h4 p-2 text-center">Spent</th>
                        <th class="h4 p-2 text-center">Taken</th>
                        <th class="h4 p-2 text-center">Remaining</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let summary of financialSummaries">
                        <td class="h4 p-2">{{summary.ownerName}}</td>
                        <td class="h4 p-2 text-center align-middle"><strong>${{summary.remainingPlayers == 0? 0 : summary.maxBid}}</strong></td>
                        <td class="h4 p-2 text-center align-middle">${{summary.totalSpent}}</td>
                        <td class="h4 p-2 text-center align-middle">{{summary.numberPlayers}}</td>
                        <td class="h4 p-2 text-center align-middle">{{summary.remainingPlayers}}</td>
                    </tr>
                </tbody>  
            </table>
        </div>
        <div class="col-lg-6 col-sm-12">
            <table class="table table-striped table-sm table-bordered">
                <thead>
                    <tr class="table-primary">
                        <th class="h3 text-center align-middle p-2">#</th>
                        <!-- <th></th> -->
                        <th class="h3 p-2">Player</th>
                        <th class="h3 p-2">Owner</th>
                        <th class="h3 p-2 text-center">Price</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container  *ngFor="let player of players; index as i">
                        <tr >
                            <td class="p-2 text-center align-middle"><span [class.h2]="i == 0" [class.h5]="i > 0">{{pageStart + players.length-i}}</span></td>
                            <!-- <td>
                                <img *ngIf="player.imageUrl" src="{{player.imageUrl}}" height="46" class="float-end">
                            </td> -->
                            <td class="p-2">
                                <span [class.h2]="i == 0" [class.h5]="i > 0">{{player.name}} <span *ngIf="stateService.hasAdminRights()">({{player.position}})</span><br>{{player.team}}</span>
                            </td>
                            <td class="p-2"><span [class.h2]="i == 0" [class.h5]="i > 0">{{player.owner?.name}}<br>
                            {{player.owner?.teamName}}</span></td>
                            <td class="p-2 text-center align-middle"><span [class.h2]="i == 0"  [class.h5]="i > 0" *ngIf="player.ownerPrice">${{player.ownerPrice}}</span></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="d-flex justify-content-center mt-5">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
</ng-template>    
