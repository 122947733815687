export const environment = {
  production: true,

  API_PROTOCOL: 'https://',
  API_HOSTNAME: 'api.kyletwogood.com',
  API_PORT: '443',
  API_PATH: '/api/',

  WS_ENABLED: true,
  WS_ENDPOINT:'wss://api.kyletwogood.com/api/rl-websocket',
  RECONNECT_INTERVAL: 2000,
};
